export const types = Object.freeze({
  pizza: 1,
  de_luxe_special: 2,
  inbakad_pizza: 3,
  kebab: 4,
  lasagne: 5,
  sallad: 6,
})

export const ingredients = Object.freeze({
  tomat: 1,
  ost: 2,
  champinjoner: 3,
  skinka: 4,
  paprika: 5,
  tonfisk: 6,
  sallad: 7,
  gurka: 8,
  ananas: 9,
  lok: 10,
  citron: 11,
  kyckling: 12,
  oliver: 13,
  farost: 14,
  rakor: 15,
  pitabrod: 16,
  peperoni: 17,
  starksas: 18,
  kott: 19,
  tunnbrod: 20,
  oxfile: 21,
  vitlok: 22,
  bearnaisesas: 23,
  gorgonzola: 24,
  mozzarella: 25,
  valnotter: 26,
  ruccola: 27,
  honung: 28,
  oregano: 29,
  banan: 30,
  curry: 31,
  kottfars: 32,
  sparris: 33,
  kronartskocka: 34,
  bacon: 35,
  agg: 36,
  musslor: 37,
  salami: 38,
  farsk_tomat: 39,
  persilja: 40,
  valfri_sas: 41,
  jordnotter: 42,
  kebabkott: 43,
  chevre: 44,
  fetaost: 45,
})

const typeMap = {
  [types.pizza]: "Pizzor",
  [types.inbakad_pizza]: "Inbakade pizzor",
  [types.de_luxe_special]: "De Luxe Special",
  [types.kebab]: "Kebab",
  [types.lasagne]: "Lasagne",
  [types.sallad]: "Sallad",
}

export function mapType(type) {
  return typeMap[type] || ""
}

const ingredientMap = {
  [ingredients.lok]: "lök",
  [ingredients.farost]: "fårost",
  [ingredients.rakor]: "räkor",
  [ingredients.pitabrod]: "pitabröd",
  [ingredients.tunnbrod]: "tunnbröd",
  [ingredients.starksas]: "stark sås",
  [ingredients.kott]: "kött",
  [ingredients.oxfile]: "oxfilé",
  [ingredients.vitlok]: "vitlök",
  [ingredients.bearnaisesas]: "bearnaisesås",
  [ingredients.valnotter]: "valnötter",
  [ingredients.kottfars]: "köttfärs",
  [ingredients.kronartskocka]: "kronärtskocka",
  [ingredients.agg]: "ägg",
  [ingredients.farsk_tomat]: "färsk tomat",
  [ingredients.valfri_sas]: "valfri sås",
  [ingredients.jordnotter]: "jordnötter",
  [ingredients.kebabkott]: "kebabkött",
  [ingredients.chevre]: "chèvre",
}

export function mapIngredient(ingredient) {
  return ingredientMap[ingredient] || Object.keys(ingredients).find(name => ingredients[name] === ingredient)
}

const items = [
  // Pizzor
  {
    number: 1,
    name: "Margerita",
    type: types.pizza,
    price: 70,
    ingredients: [ingredients.tomat, ingredients.ost],
  },
  {
    number: 2,
    name: "Funghi",
    type: types.pizza,
    price: 75,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.champinjoner],
  },
  {
    number: 3,
    name: "Prosciuotto",
    type: types.pizza,
    price: 75,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka],
  },
  {
    number: 4,
    name: "Vesuvio",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.paprika],
  },
  {
    number: 5,
    name: "Venezia",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.tonfisk],
  },
  {
    number: 6,
    name: "Capricciosa",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.champinjoner],
  },
  {
    number: 7,
    name: "Hawaii",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.ananas],
  },
  {
    number: 8,
    name: "Napoli",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.champinjoner, ingredients.rakor],
  },
  {
    number: 9,
    name: "Novara",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.rakor, ingredients.ananas],
  },
  {
    number: 10,
    name: "Fantasia",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.ananas, ingredients.banan, ingredients.curry],
  },
  {
    number: 11,
    name: "Oriental",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.kottfars, ingredients.lok],
  },
  {
    number: 12,
    name: "Vegetale",
    type: types.pizza,
    price: 80,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.champinjoner,
      ingredients.lok,
      ingredients.sparris,
      ingredients.paprika,
      ingredients.kronartskocka,
    ],
  },
  {
    number: 13,
    name: "Tomaso",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.bacon, ingredients.lok, ingredients.agg],
  },
  {
    number: 14,
    name: "Laguna",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.rakor],
  },
  {
    number: 15,
    name: "Marinara",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.rakor, ingredients.musslor],
  },
  {
    number: 16,
    name: "Pescatora",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.rakor, ingredients.tonfisk],
  },
  {
    number: 17,
    name: "Bella",
    type: types.pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.kottfars, ingredients.vitlok],
  },
  {
    number: 18,
    name: "Quattro Stagioni",
    type: types.pizza,
    price: 85,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.champinjoner,
      ingredients.rakor,
      ingredients.kronartskocka,
    ],
  },
  {
    number: 19,
    name: "Cannibale",
    type: types.pizza,
    price: 85,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.kottfars, ingredients.lok],
  },
  {
    number: 20,
    name: "Afro",
    type: types.pizza,
    price: 85,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.ananas,
      ingredients.banan,
      ingredients.curry,
    ],
  },
  {
    number: 21,
    name: "Salami",
    type: types.pizza,
    price: 85,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.salami, ingredients.lok],
  },
  {
    number: 22,
    name: "Jam-Jam",
    type: types.pizza,
    price: 90,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka, ingredients.oxfile, ingredients.bearnaisesas],
  },
  {
    number: 23,
    name: "Mama Mia",
    type: types.pizza,
    price: 90,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.oxfile, ingredients.agg, ingredients.bearnaisesas],
  },
  {
    number: 24,
    name: "Garda",
    type: types.pizza,
    price: 90,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.oxfile, ingredients.paprika, ingredients.gorgonzola],
  },
  {
    number: 25,
    name: "Stefano",
    type: types.pizza,
    price: 90,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.champinjoner,
      ingredients.farsk_tomat,
      ingredients.bearnaisesas,
    ],
  },
  {
    number: 26,
    name: "Mexicana",
    type: types.pizza,
    price: 90,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.kottfars,
      ingredients.peperoni,
      ingredients.starksas,
    ],
  },
  {
    number: 27,
    name: "Star",
    type: types.pizza,
    price: 90,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.sparris,
      ingredients.champinjoner,
      ingredients.bearnaisesas,
    ],
  },
  {
    number: 28,
    name: "Turadelli",
    type: types.pizza,
    price: 85,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.kottfars,
      ingredients.champinjoner,
      ingredients.vitlok,
    ],
  },
  {
    number: 29,
    name: "Chicken",
    type: types.pizza,
    price: 85,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.champinjoner,
      ingredients.kyckling,
      ingredients.sparris,
      ingredients.curry,
    ],
  },
  {
    number: 30,
    name: "Aysin",
    type: types.pizza,
    price: 85,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.farost,
      ingredients.lok,
      ingredients.farsk_tomat,
      ingredients.peperoni,
      ingredients.vitlok,
      ingredients.persilja,
    ],
  },
  {
    number: 31,
    name: "Piccolo",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.champinjoner,
      ingredients.lok,
      ingredients.oxfile,
      ingredients.rakor,
      ingredients.peperoni,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 32,
    name: "Padrone",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.bacon,
      ingredients.kottfars,
      ingredients.lok,
      ingredients.peperoni,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 33,
    name: "Rosso",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.paprika,
      ingredients.agg,
      ingredients.lok,
      ingredients.bearnaisesas,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 34,
    name: "Berdus",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.ananas,
      ingredients.banan,
      ingredients.curry,
      ingredients.jordnotter,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 35,
    name: "Zampik",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.bacon,
      ingredients.lok,
      ingredients.agg,
      ingredients.peperoni,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 36,
    name: "Amore",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.oxfile,
      ingredients.kottfars,
      ingredients.lok,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 37,
    name: "Super",
    type: types.pizza,
    price: 95,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.kebabkott,
      ingredients.lok,
      ingredients.farsk_tomat,
      ingredients.peperoni,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 38,
    name: "Show",
    type: types.pizza,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.bacon,
      ingredients.kottfars,
      ingredients.champinjoner,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 39,
    name: "Husets Special",
    type: types.pizza,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.bacon,
      ingredients.lok,
      ingredients.oxfile,
      ingredients.sparris,
      ingredients.oliver,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 40,
    name: "Teo Special",
    type: types.pizza,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.bacon,
      ingredients.lok,
      ingredients.oxfile,
      ingredients.kottfars,
      ingredients.farsk_tomat,
      ingredients.vitlok,
      ingredients.valfri_sas,
    ],
  },
  {
    number: 41,
    name: "Aylin Special",
    type: types.pizza,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.skinka,
      ingredients.oxfile,
      ingredients.champinjoner,
      ingredients.kottfars,
      ingredients.gorgonzola,
      ingredients.bearnaisesas,
      ingredients.valfri_sas,
    ],
  },

  // De Luxe Special
  {
    number: 1,
    name: "Chèvre & Päron",
    type: types.de_luxe_special,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.mozzarella,
      ingredients.valnotter,
      ingredients.ruccola,
      ingredients.honung,
      ingredients.oregano,
    ],
  },
  {
    number: 2,
    name: "Fyra Ostar",
    type: types.de_luxe_special,
    price: 110,
    ingredients: [
      ingredients.tomat,
      ingredients.mozzarella,
      ingredients.chevre,
      ingredients.fetaost,
      ingredients.gorgonzola,
      ingredients.ruccola,
      ingredients.oregano,
    ],
  },

  // Inbakade pizzor
  {
    number: 42,
    name: "Calzone",
    type: types.inbakad_pizza,
    price: 80,
    ingredients: [ingredients.tomat, ingredients.ost, ingredients.skinka],
  },
  {
    number: 43,
    name: "Ciao-Ciao",
    type: types.inbakad_pizza,
    price: 90,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.champinjoner,
      ingredients.lok,
      ingredients.oxfile,
      ingredients.vitlok,
    ],
  },
  {
    number: 44,
    name: "Da Vinci",
    type: types.inbakad_pizza,
    price: 90,
    ingredients: [
      ingredients.tomat,
      ingredients.ost,
      ingredients.oxfile,
      ingredients.bearnaisesas,
      ingredients.gorgonzola,
    ],
  },

  // Kebab
  {
    number: 1,
    name: "Mexicana",
    type: types.kebab,
    price: 85,
    ingredients: [
      ingredients.pitabrod,
      ingredients.kebabkott,
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.lok,
      ingredients.peperoni,
      ingredients.starksas,
    ],
  },
  {
    number: 2,
    name: "Kebabrulle",
    type: types.kebab,
    price: 85,
    ingredients: [
      ingredients.tunnbrod,
      ingredients.kebabkott,
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.lok,
      ingredients.peperoni,
      ingredients.starksas,
    ],
  },

  // Lasagne
  { name: "Lasagne al Forno", type: types.lasagne, price: 85 },

  // Sallad
  {
    number: 1,
    name: "Amerikansk Sallad",
    type: types.sallad,
    price: 85,
    ingredients: [
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.skinka,
      ingredients.ost,
      ingredients.ananas,
    ],
  },
  {
    number: 2,
    name: "Tonfisksallad",
    type: types.sallad,
    price: 85,
    ingredients: [
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.tonfisk,
      ingredients.lok,
      ingredients.citron,
    ],
  },
  {
    number: 3,
    name: "Kycklingsallad",
    type: types.sallad,
    price: 85,
    ingredients: [
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.kyckling,
      ingredients.lok,
      ingredients.paprika,
    ],
  },
  {
    number: 4,
    name: "Grekisk Sallad",
    type: types.sallad,
    price: 85,
    ingredients: [
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.lok,
      ingredients.oliver,
      ingredients.paprika,
      ingredients.farost,
    ],
  },
  {
    number: 5,
    name: "Vegetarisk Sallad",
    type: types.sallad,
    price: 85,
    ingredients: [
      ingredients.sallad,
      ingredients.tomat,
      ingredients.gurka,
      ingredients.lok,
      ingredients.oliver,
      ingredients.champinjoner,
      ingredients.ananas,
    ],
  },
  {
    number: 6,
    name: "Räksallad",
    type: types.sallad,
    price: 85,
    ingredients: [ingredients.sallad, ingredients.tomat, ingredients.gurka, ingredients.rakor, ingredients.citron],
  },
]

export function ofType(type) {
  return items.filter(item => item.type === type)
}

export function withIngredients(items, ingredients) {
  if (ingredients.length === 0) {
    return items
  }

  return items.filter(
    item => item.ingredients && ingredients.every(ingredient => item.ingredients.includes(ingredient))
  )
}

export default items
